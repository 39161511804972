<template>
    <div class="max-box">
        <!-- 头部搜索、操作 -->
        <div class="header-box">
            <div class="header-left">
                <span>大屏</span>
            </div>
        </div>
        <div class="screen-box">
            <div class="inner-box">
                <div class="alarm-msg">
                    注意:为了确保有较好的视觉体验，建议你使用长宽比为16：9且分辨率在1080P以上的设备观看大屏。
                </div>
                <div class="screen-list">
                    <div
                            class="screen-one"
                            @click.stop="toPage(item)"
                            v-for="(item, index) in screenData"
                            :key="index"
                    >
                        <div class="screen-title">
                            {{ item.title }}
                        </div>
                        <img :src="item.imgSrc" alt=""/>
                        <div class="flex-alignc-justifyc" @click.stop="handleOpen(item)">
                            <a-icon type="edit"/>
                            <div>设置</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 设置标题弹窗 -->
        <a-modal
                :visible="visible"
                @cancel="handleCancel"
                @ok="handleSubmit"
                title="大屏标题"
        >
            <a-form-model ref="form" :model="form" :rules="rules">
                <a-form-model-item label="大屏标题" prop="title">
                    <a-input v-model="form.title" placeholder="请输入大屏标题"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
    import {screenMerchant, updateScreenMerchant} from "@/api/apply.js";

    export default {
        data() {
            return {
                screenData: [
                    {
                        screenId: 1,
                        title: "巢湖学院",
                        path: "/apply/screen",
                        imgSrc: require("../../assets/screen/screen1.png"),
                    },
                    {
                        screenId: 2,
                        title: "巢湖学院（监控发电量）",
                        path: "/apply/screen2",
                        imgSrc: require("../../assets/screen/screen2.png"),
                    },
                    {
                        screenId: 3,
                        title: "巢湖学院（能源监控）",
                        path: "/apply/screen3",
                        imgSrc: require("../../assets/screen/screen3.jpg"),
                    },
                    {
                        screenId: 4,
                        title: "巢湖学院 (系统预设)",
                        path: "/apply/screen4",
                        imgSrc: require("../../assets/screen/screen4.jpg"),
                    },
                ],
                visible: false,
                form: {
                    title: "",
                },
                rules: {
                    title: [
                        {required: true, message: "请输入大屏标题", trigger: "change"},
                    ],
                },
            };
        },
        mounted() {
            this.getScreenMerchant();
        },
        methods: {
            // 获取大屏列表
            getScreenMerchant() {
                screenMerchant({}).then((res) => {
                    res.data.forEach((item) => {
                        this.screenData.forEach((item1) => {
                            if (item.screenId == item1.screenId) {
                                item1.title = item.title;
                            }
                        });
                    });
                });
            },

            toPage(item) {
                const routeUrl = this.$router.resolve({
                    path: item.path,
                    query: {
                        title: item.title
                    }
                });
                window.open(routeUrl.href, "_blank");
            },

            // 打开部门弹窗
            handleOpen(item) {
                this.form = JSON.parse(JSON.stringify(item));
                this.visible = true;
            },
            // 关闭弹窗
            handleCancel() {
                this.form = {};
                this.visible = false;
            },
            // 添加/编辑部门
            handleSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        updateScreenMerchant(this.form).then((res) => {
                            this.getScreenMerchant();
                            this.handleCancel();
                        });
                    }
                });
            },
        },
    };
</script>
<style lang="less" scoped>
    .max-box {
        width: 100%;
        height: 100%;
        padding-top: 16px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .header-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            flex-grow: 0;

            .header-left {
                span {
                    font-size: 18px;
                    color: #252b3a;
                }

                i {
                    font-size: 20px;
                }
            }
        }

        .screen-box {
            padding: 16px 0;
            border: 1px solid rgba(37, 43, 58, 0.1);
            border-radius: 4px;
            box-sizing: border-box;
            background: #fff;
            flex-grow: 1;
            overflow-y: auto;

            .inner-box {
                width: 100%;
                height: 100%;
                padding: 0 16px;

                .alarm-msg {
                    padding: 8px;
                    box-sizing: border-box;
                    width: 100%;
                    color: #ffb450;
                    background: #ffb4501a;
                }
            }

            .screen-list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 16px;

                .screen-one {
                    width: 24%;
                    padding: 8px 16px;
                    border: 1px solid rgba(0, 0, 0, 0.08);
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    margin-right: 10px;
                    cursor: pointer;

                    .screen-title {
                        font-size: 12px;
                    }

                    img {
                        width: 100%;
                        height: 174px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
</style>
